<template>
    <div class="about-us-container contents-container">
        <div class="about-us-right">
            <h2 class="underlined-title animation-container"><span>SPA</span>labが選ばれる3つの理由</h2>
            <h3 class="animation-container">結果全ては『費用対効果』に優れた提案</h3>

            <p class="animation-container">後々考えるとここのページ全く役に立ってないと<br class="br-sp">いったご経験はございませんか？？？<br>
                SPAlabでは費用対効果を考えた無駄のないサイト<br class="br-sp">設計をシンプルな料金プランと始めやすい価格帯で<br>ご提案させて頂きます。<br>
                成功の鍵は無駄を減らすことも重要です。<br class="br-sp">常に時代を読む最適化、<br class="br-tablet">効率的なホームページを<br class="br-sp">ご提供致します。<br>
                またSPA（シングルページアプリケーション）での<br class="br-sp">制作は他社では非常に高価な価格帯です。
            </p>

            <div class="compare-container animation-container">
                <table>
                        <tr>
                            <th></th>
                            <th class="focused-th">SPA lab</th>
                            <th>一般制作会社</th>
                            <th>格安制作会社</th>
                            <th>他社でSPA制作</th>
                        </tr>
                        <tr>
                            <td>制作期間</td>
                            <td class="focused-td">
                                <div>
                                    <p>最短5営業日程度から<br class="br-pc"><br class="br-sp">製作が可能。</p>
                                    <img :src="doubleCircle">
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>早くても2~3ヶ月程度</p>
                                    <img :src="singleCircle">
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>調整する事は可能だが、<br class="br-pc"><br class="br-sp">クオリティが落ちる事も。</p>
                                    <img :src="blueTriangle">
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>3ヶ月以上</p>
                                    <img :src="blueTriangle">
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>デザイン</td>
                            <td class="focused-td">
                                <div>
                                    <p>高品質テンプレート or<br class="br-pc"><br class="br-sp">オリジナルデザイン</p>
                                    <img :src="doubleCircle">
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>オリジナル<br class="br-pc"><br class="br-sp">デザインでの作成</p>
                                    <img :src="singleCircle">
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>簡易的な<br class="br-pc"><br class="br-sp">テンプレートのみ</p>
                                    <img :src="grayCross">
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>デザインや<br class="br-pc"><br class="br-sp">構成による</p>
                                    <img :src="singleCircle">
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>制作費用</td>
                            <td class="focused-td">
                                <div>
                                    <p>HP制作費0円、<br class="br-pc"><br class="br-sp">月額9980円〜、<br class="br-pc"><br class="br-sp">保守費用もコミコミ！！</p>
                                    <img :src="doubleCircle">
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>製作費のみで<br class="br-pc"><br class="br-sp">数十万円〜数百万円、<br class="br-pc"><br class="br-sp">別途月数万円の<br class="br-pc"><br class="br-sp">ランニングコストも発生</p>
                                    <img :src="grayCross">
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>月額や製作費は安価だが、<br class="br-pc"><br class="br-sp">別途保守費等で請求される<br class="br-pc"><br class="br-sp">事がほとんど</p>
                                    <img :src="grayCross">
                                </div>
                            </td>
                            <td>
                                <div>
                                    <p>80万円〜数百万円<br class="br-pc"><br class="br-sp">保守管理費用別で相場<br class="br-pc"><br class="br-sp">月額1.5万円～3万円</p>
                                    <img :src="grayCross">
                                </div>
                            </td>
                        </tr>
                    </table>
            </div>
            <div class="about-us-text animation-container">
                <p>従来のホームページ制作と同じ価格帯での制作は<br class="br-sp">今では日本ではまだまだ難しいの<br class="br-tablet">現状ですが<br class="br-sp">弊社には経験豊富なデザイナー、<br class="br-pc">エンジニアの<br class="br-sp">知恵と経験からこの価格帯での<br class="br-tablet">プランを<br class="br-sp">実現致しました。</p>
                <img :src="engineer">
            </div>

            <h3 class="animation-container">SPAでの他社との差別化</h3>
            <div class="about-us-text animation-container">
                <p>ユーザー体験を最適化に重視した制作で成果に<br class="br-sp">繋げ他社とのホームページとの<br class="br-tablet">差をつけましょう！<br class="br-pc"><br class="br-sp">
                    ページ時間『0秒』の快適さはユーザーの<br class="br-tablet">滞在率を<br class="br-sp">上げることができます。
                </p>
                <img :src="icon01">
            </div>

            <h3 class="animation-container">全ての工程で『結果の仕組み』を考えます</h3>
            <h4 class="animation-container">ホームページ制作</h4>
            <div class="about-us-steps animation-container">
                <div class="about-us-step">
                    <img :src="icon02">
                    <p>ヒアリング<span class="pc-version">・</span><br class="br-tablet">分析</p>
                </div>
                <div class="about-us-step">
                    <img :src="icon04">
                    <p>課題の解決<span class="pc-version">・</span><br class="br-tablet">戦略のご提案</p>
                </div>
                <div class="about-us-step">
                    <img :src="icon05">
                    <p>ご契約</p>
                </div>
                <div class="about-us-step">
                    <img :src="icon06">
                    <p>デザイン<span class="pc-version">・</span><br class="br-tablet">サイト構築</p>
                </div>
                <div class="about-us-step">
                    <img :src="icon07">
                    <p>納品</p>
                </div>
            </div>
            <a href="#flow" class="inner-btn animation-container">詳しく見る</a>
        </div>
    </div>
</template>
<script>

import pic from '../assets/about-us.png'
import cube from '../assets/cube.png'
import icon01 from '../assets/icon01.png'
import icon02 from '../assets/icon02.png'
import icon03 from '../assets/icon03.png'
import icon04 from '../assets/icon04.png'
import icon05 from '../assets/icon05.png'
import icon06 from '../assets/icon06.png'
import icon07 from '../assets/icon07.png'
import doubleCircle from '../assets/double-circle.png'
import singleCircle from '../assets/single-circle.png'
import blueTriangle from '../assets/blue-triangle.png'
import grayCross from '../assets/gray-cross.png'
import engineer from '../assets/engineer.png'

export default {
    name: 'about-us',
    components: {

    },
    data() {
        return {
            pic: pic,
            cube: cube,
            icon01: icon01,
            icon02: icon02,
            icon03: icon03,
            icon04: icon04,
            icon05: icon05,
            icon06: icon06,
            icon07: icon07,
            doubleCircle: doubleCircle,
            singleCircle: singleCircle,
            blueTriangle: blueTriangle,
            grayCross: grayCross,
            engineer: engineer
        }
    },
}
</script>