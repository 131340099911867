<template>
    <div class="reason-container">
        <div class="background-title">
            <h2 class="animation-container">SPA lab プラン一覧</h2>
            <img id="title_background01" :src="titleBackground" alt="title-background">
        </div>
        <div class="template-preview animation-container">
            <div class="template-pics">
                <img id="artytech_pc" class="template-pc" :src="currentPc" alt="ArtytechテンプレートPC">
                <div id="artytech_moving" class="template-moving">
                    <img id="artytech_laptop" class="template-laptop" :src="currentLaptop" alt="ArtytechテンプレートLaptop">
                    <img id="artytech_tablet" class="template-tablet" :src="currentTablet" alt="ArtytechテンプレートTablet">
                    <img id="artytech_sp" class="template-sp" :src="currentSp" alt="ArtytechテンプレートSP">
                </div>
            </div>
            <div class="template-buttons">
                <div id="reason_left_btn" class="left-button inactive" @click="moveTemplateLeft"><img :src="leftBtn">
                </div>
                <div id="reason_right_btn" class="right-button" @click="moveTemplateRight"><img :src="rightBtn"></div>
            </div>
            <div class="template-texts">
                <div class="reason-header">
                    <p class="list-title" v-html="plans[currentIndex].title"></p>
                </div>
                <p>
                    {{ plans[currentIndex].description }}
                </p>
                <div class="plan-prices">
                    <div class="plan-price-hp">
                        <p>HP制作費</p>
                        <p class="plan-price">0円</p>
                    </div>
                    <div class="plan-price-month">
                        <p>月額</p>
                        <p class="plan-price">{{ plans[currentIndex].price }}</p>
                    </div>
                    
                </div>
                <div class="plan-subs">
                    <p>TOP+{{ plans[currentIndex].pages }}ページの作成</p>
                    <p v-if="plans[currentIndex].additional">ページ追加も可能</p>
                    <div class="plan-price-tax">
                        <p>※税込{{ plans[currentIndex].tax }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="reason-boxes animation-container">
            <div class="reason-list-box" v-for="index in planIconSets[currentIndex]" :key="index">
                <div class="reason-header">
                    <p class="list-title">{{ planIcons[index - 1].title }}</p>
                </div>
                <div class="reason-body">
                    <img :src="planIcons[index - 1].icon" alt="モバイルファーストインデックスの重要性">
                </div>
            </div>
        </div>
        <optional-services id="optional"></optional-services>
    </div>
</template>
<script>

import artytechPc from '../assets/artytech-pc.png'
import artytechLaptop from '../assets/artytech-laptop.png'
import artytechTablet from '../assets/artytech-tablet.png'
import artytechSp from '../assets/artytech-sp.png'
import creaidPc from '../assets/creaid-pc.png'
import creaidLaptop from '../assets/creaid-laptop.png'
import creaidTablet from '../assets/creaid-tablet.png'
import creaidSp from '../assets/creaid-sp.png'
import mjPc from '../assets/mj-pc.png'
import mjLaptop from '../assets/mj-laptop.png'
import mjTablet from '../assets/mj-tablet.png'
import mjSp from '../assets/mj-sp.png'
import ecPc from '../assets/ec-pc.png'
import ecLaptop from '../assets/ec-laptop.png'
import ecTablet from '../assets/ec-tablet.png'
import ecSp from '../assets/ec-sp.png'
import titleBackground from '../assets/title-background.jpg'
import plan01 from '../assets/plan01.png'
import plan02 from '../assets/plan02.png'
import plan03 from '../assets/plan03.png'
import plan04 from '../assets/plan04.png'
import plan05 from '../assets/plan05.png'
import plan06 from '../assets/plan06.png'
import plan07 from '../assets/plan07.png'
import plan08 from '../assets/plan08.png'
import plan09 from '../assets/plan09.png'

import OptionalServices from './OptionalServices.vue'

export default {
    name: 'the-reason',
    data() {
        return {
            titleBackground: titleBackground,
            templates: [
                {
                    'pc': mjPc,
                    'laptop': mjLaptop,
                    'tablet': mjTablet,
                    'sp': mjSp,
                },
                {
                    'pc': creaidPc,
                    'laptop': creaidLaptop,
                    'tablet': creaidTablet,
                    'sp': creaidSp,
                },
                {
                    'pc': artytechPc,
                    'laptop': artytechLaptop,
                    'tablet': artytechTablet,
                    'sp': artytechSp,
                },
                {
                    'pc': ecPc,
                    'laptop': ecLaptop,
                    'tablet': ecTablet,
                    'sp': ecSp,
                },
            ],
            plans: [
                {
                    'title': 'シンプルプラン',
                    'description': 'シンプル・スピーディーに圧倒的な低価格で貴社ホームページを制作',
                    'price': '9,980円',
                    'tax': '10,978円',
                    'additional': false,
                    'pages': 5
                },
                {
                    'title': '<span>★１番人気★</span>スタンダードプラン',
                    'description': '高品質テンプレートを使用＋ニュースブログ機能付き！',
                    'price': '11,860円',
                    'tax': '13,046円',
                    'additional': true,
                    'pages': 7
                },
                {
                    'title': 'オリジナルオーダープラン',
                    'description': 'テンプレートを使わずオリジナルHPを制作',
                    'price': '16,800円',
                    'tax': '18,480円',
                    'additional': true,
                    'pages': 7
                },
                {
                    'title': 'ECサイト制作プラン',
                    'description': '自社製品をオンラインで簡単販売！',
                    'price': '25,800円~',
                    'tax': '28,380円~',
                    'additional': true,
                    'pages': 7
                },
            ],
            planIcons: [
                {
                    'icon': plan01,
                    'title': '高品質なテンプレート',
                },
                {
                    'icon': plan02,
                    'title': '内部SEO対策',
                },
                {
                    'icon': plan03,
                    'title': 'スマートフォン対応',
                },
                {
                    'icon': plan04,
                    'title': 'お問い合わせフォームの設置',
                },
                {
                    'icon': plan05,
                    'title': 'サーバー・ドメイン管理',
                },
                {
                    'icon': plan06,
                    'title': 'メールアドレス作成可能',
                },
                {
                    'icon': plan07,
                    'title': 'カート機能',
                },
                {
                    'icon': plan08,
                    'title': 'アカウント機能',
                },
                {
                    'icon': plan09,
                    'title': '決済機能',
                },
            ],
            planIconSets: [
                6, 7, 7, 9
            ],
            currentIndex: 0,
            currentPc: null,
            currentLaptop: null,
            currentTablet: null,
            currentSp: null,
            leftBtn: require('../assets/left-button.png'),
            rightBtn: require('../assets/right-button.png'),
        }
    },
    components: {
        'optional-services': OptionalServices
    },
    mounted() {
        this.currentPc = this.templates[this.currentIndex].pc;
        this.currentLaptop = this.templates[this.currentIndex].laptop;
        this.currentTablet = this.templates[this.currentIndex].tablet;
        this.currentSp = this.templates[this.currentIndex].sp;
    },
    methods: {
        moveTemplateRight() {
            const leftBtn = document.getElementById('reason_left_btn');
            const rightBtn = document.getElementById('reason_right_btn');
            const templateMoving = document.getElementById('artytech_moving');
            const templatePc = document.getElementById('artytech_pc');

            if (this.currentIndex < this.templates.length - 1) {
                if (this.currentIndex === 0) {
                    leftBtn.classList.remove('inactive');
                }
                this.currentIndex++;
                templateMoving.style.transform = 'translateX(-10%)';
                templatePc.style.transform = 'translateX(-10%)';
                templateMoving.style.opacity = '0';
                templatePc.style.opacity = '0';
                setTimeout(() => {
                    templateMoving.style.transform = 'translateX(0)';
                    templatePc.style.transform = 'translateX(0)';
                    templateMoving.style.opacity = '1';
                    this.currentPc = this.templates[this.currentIndex].pc;
                    this.currentLaptop = this.templates[this.currentIndex].laptop;
                    this.currentTablet = this.templates[this.currentIndex].tablet;
                    this.currentSp = this.templates[this.currentIndex].sp;
                    this.currentPlan = this.plans[this.currentIndex];
                    templatePc.style.opacity = '1';
                }, 500);
            }
            if (this.currentIndex === this.templates.length - 1) {
                rightBtn.classList.add('inactive');
            }
            console.log(this.currentIndex)
        },
        moveTemplateLeft() {
            const leftBtn = document.getElementById('reason_left_btn');
            const rightBtn = document.getElementById('reason_right_btn');
            const templateMoving = document.getElementById('artytech_moving');
            const templatePc = document.getElementById('artytech_pc');
            if (this.currentIndex > 0) {
                if (this.currentIndex === this.templates.length - 1) {
                    rightBtn.classList.remove('inactive');
                }
                this.currentIndex--;
                templateMoving.style.transform = 'translateX(10%)';
                templatePc.style.transform = 'translateX(10%)';
                templateMoving.style.opacity = '0';
                templatePc.style.opacity = '0';
                setTimeout(() => {
                    templateMoving.style.transform = 'translateX(0)';
                    templatePc.style.transform = 'translateX(0)';
                    templateMoving.style.opacity = '1';
                    this.currentPc = this.templates[this.currentIndex].pc;
                    this.currentLaptop = this.templates[this.currentIndex].laptop;
                    this.currentTablet = this.templates[this.currentIndex].tablet;
                    this.currentSp = this.templates[this.currentIndex].sp;
                    this.currentPlan = this.plans[this.currentIndex];
                    templatePc.style.opacity = '1';
                }, 500);
            }
            if (this.currentIndex === 0) {
                leftBtn.classList.add('inactive');
            }
            console.log(this.currentIndex)
        }
    }
}
</script>